import React, { useState } from "react";
import {
  Input, Form, Button, FormFieldLabel, Spinner
} from "bronson-react/dist/index";
import FetchToken from "../service/token";
import "../App.css";

const Consent = props => {

  console.log(JSON.stringify(props));

  const [displayForm, setDisplayForm] = useState(true);
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayFailure, setDisplayFailure] = useState(false);

  const [state, setState] = useState({
    username: "",
    password: "",
    country: "",
    brand: ""
  });

  const handleSubmit = async e => {

    e.preventDefault();
    setDisplayForm(false);
    setDisplaySpinner(true);
    setDisplayFailure(false);

    const result = await FetchToken(e.target.username.value, e.target.password.value, e.target.country.value, e.target.brand.value);

    if (result.status === 200) {

      setDisplaySpinner(false);

      if(result.data.Errors) {
        setDisplayFailure(true);
        setDisplayForm(true);
      } else {
        setDisplaySuccess(true);
        setDisplayFailure(false);
      }

      if (props.onLogin) {

        props.onLogin(result.data);

      }

    }

    if (result.status !== 200) {

      setDisplayFailure(true);
      setDisplayForm(true);
      setDisplaySpinner(false);

    }

  };

  const handleChange = e => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });

  };

  return (
    <div>
      {displaySuccess && <div>Login Successful! You're all set to access feature apps.</div>}

      {displayForm && (
        <Form id="consentForm" onSubmit={handleSubmit}>
          <FormFieldLabel for="=username">Username</FormFieldLabel>
          <Input name="username" id="username" value={state.username} onChange={handleChange}></Input>

          <FormFieldLabel for="password">Password</FormFieldLabel>
          <Input name="password" id="password" value={state.password} onChange={handleChange}></Input>

          <FormFieldLabel for="country">Country</FormFieldLabel>
          <Input name="country" id="country" value={state.country} onChange={handleChange}></Input>

          <FormFieldLabel for="brand">Brand</FormFieldLabel>
          <Input name="brand" id="brand" value={state.brand} onChange={handleChange}></Input>

          <Button type="submit">Submit</Button>
        </Form>
      )}

      {displayFailure && <div className="alertError">We could not find a customer with the information provided. <br></br>Please try again.</div>}

      {displaySpinner && (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );

};

export default Consent;
