import React from "react";
import "./App.css";
import "@vwfs-bronson/bronson-vw/dist/css/style.min.css";
import "@vwfs-bronson/bronson-vw/dist/js/bronson.min.js";
import {
  Route, Switch, BrowserRouter
} from "react-router-dom";
import zoid from "zoid";
import Consent from "./component/consent";

const ConsentZoidComponent = zoid.create({
  tag: "consent-component",
  url: "%REACT_APP_FEATURE_APP_HOST%",
  //url: "http://localhost:3000/consent",
  props: {
    externalId: {
      type: "string",
      required: true
    },
    brand: {
      type: "string",
      required: true
    },
    country: {
      type: "string",
      required: true
    },
    onLogin: {
      type: "function",
      required: false
    }
  }
});

const App = props => (
  <BrowserRouter>
    <Switch>
      <Route path="/" render={router => <Consent {...props} {...window.xprops} {...router} />} />
    </Switch>
  </BrowserRouter>
);

export default App;
