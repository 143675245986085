import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const guid = uuidv4();

const FetchToken = async (username, password, country, brand) => {
  const config = {
    method: "post",
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      "Content-Type": "application/json",
      "sourceId": "testharness-consent",
      "correlationId": guid,
    },
  };

  return await axios
    .post(
      "/dashboard/login",
      {
        username,
        password,
        country,
        brand,
        externalId: 'test@vw.com'
      },
      config
    )
    .then((response) => response)
    .catch((error) => error.response);
};
export default FetchToken;
